import React from "react"
import { uniqueId } from "lodash"

import style from "./index.module.scss"

const Input = React.forwardRef( ({label, isRequired, name, errors, placeholder, type, onChange}, ref) => {

  const uid = uniqueId("ps-");

  return (
    <>
      <label className={style.label} htmlFor={uid}>
        {label}
        {isRequired && (<span className={style.required}>*</span>)}
      </label>

      <input
        id={uid}
        className={style.input}
        name={name}
        ref={ref}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
      />

      {errors && (
        <span className={style.error}>
          {errors.message}
        </span>
      )}

    </>
  )
})

//
// Input.propTypes = {
//
// }
//
// Input.defaultProps = {
//
// }

export default Input
