import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Grid from "@material-ui/core/Grid"

const Gallery = ( {images} ) => {

  const images_renderd = images.map( (image, index) => (
    <Grid item xs={12} md={3} key={index}>
      <Img
        fluid={image.gallery_image.localFile.childImageSharp.fluid}
      />
    </Grid>
  ))

  return (
    <Grid
      container
      spacing={4}
    >
      {images_renderd}
    </Grid>
  )
}


Gallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired
}

export default Gallery
