import React from "react"
import style from "./index.module.scss"
import { uniqueId } from "lodash"


const Textarea = React.forwardRef( ({label, isRequired, name, errors, placeholder, rows, onChange}, ref) => {

  const uid = uniqueId("ps-");

  return (
    <>
      <label className={style.label} htmlFor={uid}>
        {label}
        {isRequired && (<span className={style.required}>*</span>)}
      </label>

      <textarea
        id={uid}
        className={style.textarea}
        name={name}
        ref={ref}
        placeholder={placeholder}
        rows={rows}
        onChange={onChange}
      />

      {errors && (
        <span className={style.error}>
          {errors.message}
        </span>
      )}

    </>
  )
})

// Textarea.propTypes = {
//
// }
//
// Textarea.defaultProps = {
//
// }

export default Textarea
