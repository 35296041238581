import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Img from "gatsby-image"
import Layout from "components/layout"
import Section from "components/section"
import Atf from "components/atf"
import ContactForm from "components/contact-form"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Button"
import Gallery from "components/gallery"
import PhoneIcon from "@material-ui/icons/PhoneInTalk"
import Box from "@material-ui/core/Box"
import Pattern from "components/pattern"
import SEO from "components/seo"


const ContactPage = ( {data} ) => {

  const node = data.allPrismicKontakt.edges[0].node.data;

  const locations = node.locations.map( (location, index) => (
    <div key={index}>
      <div className="mi-0">
        <RichText
          render={location.location_heading.raw}
        />
      </div>
      <div className="mi-0 mb-2">
        <RichText
          render={location.location_adress.raw}
        />
      </div>
    </div>
  ))

  return (
    <Layout>
      <SEO
        title="Kontakt"
        description="Möchten Sie einen Termin für ein Erstgespräch vereinbaren oder haben Fragen?"
        canonical
        canonicalUri="/kontakt/"
      />
      <Atf heading="Kontakt"/>
      <Section
        hasMargin={true}
        hasBackground={false}
        hasPadding={false}
      >
        <Grid
          container
          spacing={0}
          justify="center"
        >
          <Grid item xs={12} md={8} className="text-center">
            <RichText
              render={node.intro_heading.raw}
            />
            <RichText
              render={node.intro_text.raw}
            />
            <Box mt={5}>
              <Link
                href="tel:004367762760453"
                aria-label="anrufen"
                variant="contained"
                color="primary"
                disableElevation
                startIcon={<PhoneIcon titleAccess="phone"/>}
                my={5}
              >
                {node.phone_number.text}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Section>

      <Pattern
        color="grey"
        size="sm"
        offset="sm"
        position="right"
      />

      <Section
        hasMargin={true}
        hasBackground={true}
        hasPadding={true}
      >
        <ContactForm />
      </Section>

      <Pattern
        color="grey"
        size="md"
        offset="lg"
        position="left"
        mirror
      />

      <Section
        hasMargin={true}
        hasBackground={false}
        hasPadding={false}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12} md={7}>
            <Img
              fluid={node.map_image.localFile.childImageSharp.fluid}
            />
          </Grid>
          <Grid item xs={12} md={5}>

            { /*
              <RichText
                render={node.contact_heading}
              />
              <RichText
                render={node.contact_text}
              />
            */}

            {locations}
          </Grid>
        </Grid>

        <Box mt={5}>
          <Gallery
            images={node.gallery}
          />
        </Box>

      </Section>

    </Layout>
  )
}

export const query = graphql`
  query ContactQuery {
    allPrismicKontakt {
      edges {
        node {
          data {
            intro_heading {
              raw
            }
            intro_text {
              raw
            }
            phone_link {
              url
            }
            phone_number {
              text
            }
            map_image {
              localFile {
                childImageSharp {
                  fluid (maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            locations {
              location_adress {
                raw
              }
              location_heading {
                raw
              }
            }
            gallery {
              gallery_image {
                localFile {
                  childImageSharp {
                    fluid (maxWidth: 500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ContactPage
