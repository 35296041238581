import React, {useState} from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import emailjs from 'emailjs-com';
import { useForm } from "react-hook-form";
import { requiredValidation, emailValidation } from "./validations.js"
import Input from "components/input"
import Textarea from "components/textarea"
import Checkbox from "components/checkbox"
import CircularProgress from '@material-ui/core/CircularProgress';

import style from "./index.module.scss"

const ContactForm = () => {

  const { register, handleSubmit, watch, errors, reset } = useForm();

  const [isSending, setIsSending] = useState(false);
  const [isSuccesfull, setIsSuccesfull] = useState(false);
  const [isError, setIsError] = useState(false);

  function sendEmail(data) {

    var service_id = "default_service";
    var template_id = "website_formular";
    const user_id = 'user_TetaT0n4mEOmcMuMiRiRj'

    const mail_data = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: data.phone,
      message: data.message,
    }

    setIsSending(true);
    emailjs.send(service_id, template_id, mail_data, user_id)
      .then((result) => {
          setIsSending(false);
          setIsSuccesfull(true);
          reset({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            message: "",
          });
      }, (error) => {
          setIsSending(false);
          setIsError(true);
      });
  }

  const clearAlert = () => {
    setIsSuccesfull(false);
    setIsError(false);
  }

  watch("firstname");
  watch("lastname");
  watch("email");
  watch("phone");
  watch("message");
  watch("optin");

  return (
  <form onSubmit={handleSubmit(sendEmail)}>

    <Grid
      container
      spacing={4}
      justify="center"
    >

      <Grid item xs={12} md={6}>
        <Input
          name="firstname"
          label="Vorname"
          placeholder=""
          isRequired
          ref={register(requiredValidation)}
          errors={errors.firstname}
          onChange={clearAlert}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          name="lastname"
          label="Nachname"
          placeholder=""
          isRequired
          ref={register(requiredValidation)}
          errors={errors.lastname}
          onChange={clearAlert}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          name="email"
          label="E-Mail Adresse"
          placeholder=""
          isRequired
          ref={register(emailValidation)}
          errors={errors.email}
          onChange={clearAlert}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          name="phone"
          label="Telefonnummer"
          placeholder=""
          onChange={clearAlert}
        />
      </Grid>
      <Grid item xs={12}>
        <Textarea
          label="Nachricht"
          name="message"
          placeholder=""
          isRequired
          rows="6"
          ref={register(requiredValidation)}
          errors={errors.message}
          onChange={clearAlert}
        />
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          label="Datenschutz"
          isRequired
          name="optin"
          ref={register(requiredValidation)}
          errors={errors.optin}
          text="Ja, ich bin damit einverstanden, dass meine Daten laut Datenschutzverordnung übermittelt und verarbeitet werden."
          onChange={clearAlert}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <div className={style.wrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            disabled={isSending}
            fullWidth
          >
            Nachricht senden
          </Button>
          {isSending && <CircularProgress size={24} className={style.buttonProgress} />}
        </div>
      </Grid>
      <Grid item xs={12} md={9}>
        { isSuccesfull && (
          <div className={style.mailSuccess}>
            Danke! Ihre Nachricht wurde erfolgreich gesendet. Ich melde mich zeitnah bei Ihnen.
          </div>
        )}
        { isError && (
          <div className={style.mailError}>
            Die Nachricht konnte leider nicht gesendet werden. Bitte versuchen Sie es erneut.
          </div>
        )}
      </Grid>
    </Grid>
  </form>

  )
}


export default ContactForm
