export const requiredValidation = {
  required: "Dieses Feld ist ein Pflichtfeld",
}

export const emailValidation = {
  required: requiredValidation.required,
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Ungültige E-Mail Adresse"
  }
}
