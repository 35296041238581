import React from "react"
import style from "./index.module.scss"

const Checkbox = React.forwardRef( ({label, isRequired, name, errors, placeholder, text, onChange}, ref) => {

  return (
    <>
      <label className={style.label}>
        {label}
        {isRequired && (<span className={style.required}>*</span>)}
      </label>

      <label className={style.wrapper}>
        <input
          className={style.input}
          name={name}
          ref={ref}
          placeholder={placeholder}
          type="checkbox"
          onChange={onChange}
        />
        <span className={style.text}>
          {text}
        </span>
      </label>


      {errors && (
        <span className={style.error}>
          {errors.message}
        </span>
      )}

    </>
  )
})


Checkbox.propTypes = {

}

Checkbox.defaultProps = {

}

export default Checkbox
